import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week `}</em></p>
    <p>{`DB Single Arm OHS 3×6/arm`}</p>
    <p>{`OH Band Pull Aparts 3×12`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`17.1 “light”`}</strong></p>
    <p>{`10-DB Snatches (Alternating Arms, 50/35)`}</p>
    <p>{`15-Burpee Box Jump Overs (Box Facing, 24/20″)`}</p>
    <p>{`20-DB Snatches`}</p>
    <p>{`15-Burpee Box Jump Overs`}</p>
    <p>{`30-DB Snatches`}</p>
    <p>{`15-Burpee Box Jump Overs`}</p>
    <p>{`40-DB Snatches`}</p>
    <p>{`15-Burpee Box Jump Overs`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`Bonus WOD`}{`*`}</strong></p>
    <p>{`15-12-9 reps each of:`}</p>
    <p>{`DB OH Walking Lunges per leg (50/35)`}</p>
    <p>{`T2B`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      